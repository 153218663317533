import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useAssets, useStructures } from '@/core'
import {
  getActivityProgress,
  createOrUpdateActivityProgress,
} from '../services/progress'
import useCurtain from '@/composables/useCurtain'

const activity = ref(null)
const activityIcon = ref(null)
const activityProgressItems = ref([])
const initialized = ref(false)

export default () => {
  const route = useRoute()
  const router = useRouter()
  const { fetchAsset, getAssetById } = useAssets()
  const { t, locale } = useI18n()
  const { setCurtain } = useCurtain()
  const { getStructuresBySlugPath, getStructuresChildrens } = useStructures()
  const activityTitle = computed(
    () => (activity.value && activity.value.name) || t('ACTIVITY'),
  )
  const setIcon = async () => {
    await fetchAsset(activity.value.cover)
    activityIcon.value = getAssetById(activity.value.cover)
  }
  const activityChildrens = computed(
    () =>
      (activity.value &&
        getStructuresChildrens(activity.value.id, 'OBJECT', locale.value)) ||
      [],
  )
  const currentElement = computed(() => {
    let curr = 0

    if (
      activityChildrens.value &&
      activityChildrens.value.length &&
      route.params.element
    ) {
      curr = activityChildrens.value.findIndex(
        ac => ac.slug === route.params.element,
      )
    }

    return curr
  })
  const getCurrentElement = computed(
    () =>
      activityChildrens.value.filter(
        ac => ac.slug === route.params.element,
      )[0] || null,
  )

  const getFirstIncompleteElement = computed(() => {
    let el = null

    if (activityChildrens.value && activityChildrens.value.length) {
      const lastCompletedIndex = activityChildrens.value.findIndex(
        ac =>
          ac.id ===
          activityProgressItems.value[activityProgressItems.value.length - 1],
      )
      const found = activityChildrens.value[lastCompletedIndex + 1]

      if (found) {
        el = found
      }
    }

    return el
  })
  const isLastElement = computed(
    () =>
      activityChildrens.value &&
      activityChildrens.value.length === currentElement.value + 1,
  )
  const isCompletedElement = computed(() => {
    return (
      getCurrentElement.value &&
      activityProgressItems.value &&
      activityProgressItems.value.includes(getCurrentElement.value.id)
    )
  })

  const setActivity = async activitySlugPath => {
    initialized.value = false
    const activityFound = getStructuresBySlugPath(
      activitySlugPath,
      false,
      'DIRECTORY',
      locale.value,
    )

    if (!activityFound) return
    activity.value = activityFound
    await setupActivityProgress()
    setElement()
    setIcon()
    initialized.value = true
  }
  const setElement = elSlug => {
    if (!activityChildrens.value || !activityChildrens.value.length) return
    if (getFirstIncompleteElement.value && !initialized.value) {
      return router.push({
        params: { element: getFirstIncompleteElement.value.slug },
      })
    }
    if (elSlug) return router.push({ params: { element: elSlug } })

    router.push({ params: { element: activityChildrens.value[0].slug } })
  }
  const goToArena = () => {
    router.push({ name: 'Arena', params: { arena: route.params.arena } })
  }
  const prevElement = () => {
    if (!activityChildrens.value) return

    const prevEl = activityChildrens.value[currentElement.value - 1]

    if (!prevEl) return false

    setElement(prevEl.slug)
  }
  const getSavedActivityProgress = async () => {
    const ap = await getActivityProgress(activity.value.id)

    return ap
  }

  const saveElementState = async (elementId, state) => {
    const ap = await getSavedActivityProgress()
    ap.itemStates = {
      ...ap.itemStates,
      [elementId]: state,
    }
    await createOrUpdateActivityProgress(activity.value.id, ap)
  }

  const getElementState = async elementId => {
    const ap = await getSavedActivityProgress()
    return ap.itemStates ? ap.itemStates[elementId] : null
  }

  const nextElement = async () => {
    if (!activityChildrens.value || !isCompletedElement.value) return

    const nextEl = activityChildrens.value[currentElement.value + 1]

    if (!nextEl) {
      return openFeedback()
    }

    setElement(nextEl.slug)
  }

  const setElementComplete = async () => {
    const ap = await getSavedActivityProgress()
    if (!activityProgressItems.value.includes(getCurrentElement.value.id)) {
      activityProgressItems.value.push(getCurrentElement.value.id)
      ap.itemsCompleted = activityProgressItems.value
      await createOrUpdateActivityProgress(activity.value.id, ap)

      const hasNextElement =
        activityChildrens.value &&
        activityChildrens.value[currentElement.value + 1]
      if (!hasNextElement) {
        completeActivity()
      }
    }
  }

  const completeActivity = async () => {
    const ap = await getSavedActivityProgress()

    ap.reset = false
    ap.done = true

    await createOrUpdateActivityProgress(activity.value.id, ap)
    await saveActivityInArena()

    return ap
  }
  const saveActivityInArena = async () => {
    let ap = await getActivityProgress(activity.value.parent)

    if (!ap) {
      ap = {
        itemsCompleted: [activity.value.id],
      }
    } else {
      if (ap.itemsCompleted.indexOf(activity.value.id) === -1) {
        ap.itemsCompleted.push(activity.value.id)
      }
    }

    await createOrUpdateActivityProgress(activity.value.parent, ap)

    return ap
  }
  const hasPrevElement = computed(() => {
    if (!activityChildrens.value) return

    const prevEl = activityChildrens.value[currentElement.value - 1]

    return prevEl
  })
  const openFeedback = () => {
    setCurtain('CurtainFeedback')
  }
  const progress = computed(
    () =>
      (activityChildrens.value &&
        ((currentElement.value + 1) * 100) / activityChildrens.value.length) ||
      0,
  )
  const setupActivityProgress = async () => {
    let ap = await getSavedActivityProgress()

    if (!ap) {
      ap = await createOrUpdateActivityProgress(activity.value.id, {
        parent: activity.value.parent,
      })
    }

    activityProgressItems.value = ap.itemsCompleted || []

    return ap
  }

  return {
    activity,
    activityChildrens,
    activityTitle,
    activityIcon,
    currentElement,
    isLastElement,
    isCompletedElement,
    hasPrevElement,
    getCurrentElement,
    getFirstIncompleteElement,
    progress,
    activityProgressItems,
    completeActivity,
    setElement,
    prevElement,
    nextElement,
    setActivity,
    goToArena,
    saveElementState,
    getElementState,
    setElementComplete,
  }
}
