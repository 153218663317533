import { computed } from 'vue'

import DB from '@/core/services/db'
import useAuth from '@/core/useAuth'
import useTenant from '@/core/useTenant'

const db = new DB()
const { user } = useAuth()
const { tenant } = useTenant()
const prefix = computed(() => `${tenant.value.namespace}-${tenant.value.slug}-${user.value._id}`)

const createOrUpdateActivityProgress = async (id, data) => {
  try {
    const { parent, itemsCompleted, reset, done, itemStates } = data

    const item = await db.createOrUpdate({
      _id: `${prefix.value}-${id}`,
      parent,
      itemsCompleted,
      reset,
      itemStates,
      done,
    })

    return item
  } catch(err) {
    console.error(err)
  }
}

const getActivityProgress = async id => {
  try {
    const progress = await db.getData(`${prefix.value}-${id}`)

    return progress
  } catch(err) {
    return false
  }
}

export {
  getActivityProgress,
  createOrUpdateActivityProgress,
}
