<template>
  <component
    :is="currentComponent"
    class="c-button"
    :class="[
      `c-button--${type}`,
      {'c-button--small': small },
      {'c-button--mini': mini },
      {'c-button--circle': circle },
      {'c-button--rounded': rounded },
      {'c-button--disabled': disabled }
    ]"
    v-bind="attributes"
    :disabled="disabled"
  >
    <span v-if="icon" class="vb-icon" :class="[`vb-icon-${icon}`,`align-${iconPos}`]"></span> 
    <slot>
    </slot>
  </component>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'TheButton',

  props: {
    to: String,
    type: {
      type: String,
      default: 'basic',
    },
    icon: {
      type: String,
      default: null,
    },
    iconPos:{
      type: String,
      default: 'left',
    },
    small: Boolean,
    mini: Boolean,
    circle: Boolean,
    rounded: Boolean,
    disabled: Boolean,
  },

  setup(props) {
    const currentComponent = computed(() => {
      if (props.to) {
        return 'router-link'
      } else {
        return 'button'
      }
    })
    const attributes = computed(() => {
      if (currentComponent.value === 'router-link') {
        return {
          to: props.to,
        }
      } else {
        return {}
      }
    })

    return {
      attributes,
      currentComponent,
    }
  },
}
</script>
