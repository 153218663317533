<template>
    <Logo />
  <div class="c-topbar-login c-topbar__modal c-topbar__modal--right">
      <a href="https://www.fagbokforlaget.no/Synopsis-nettressurs-elev/I9788211035219" class="c-topbar-access__btn" target=”_blank”>
        {{$t('LOGIN_GET_ACCESS')}}
      </a>
      <TheButton type="primary" @click="login" :text="true" class="c-topbar-login__btn">{{$t("LOGIN")}}</TheButton>
  </div>
  <UserMenu />
</template>

<script>
import { useAuth } from '@/core'

import UserMenu from '@/components/UserMenu'
import TheButton from '@/components/TheButton'
import Logo from '@/components/Logo'

export default {
  components: {
    UserMenu,
    TheButton,
    Logo,
  },

  setup() {
    const { login } = useAuth()

    return {
      login,
    }
  },
}
</script>