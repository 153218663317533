import { ref, computed, watch } from 'vue'
import { useStructures } from '@/core'
import {
  getActivityProgress,
  createOrUpdateActivityProgress,
} from '../services/progress'
import useTranslations from '@/composables/useTranslations'

const { gqlContentQuery } = useTranslations()
const { getStructuresChildrens, fetchStructuresChildren } = useStructures()

const isModalOpen = ref(false)
const modalContent = ref(null)
const allowModalClose = ref(false)
const modalContentItems = computed(() => {
  return getStructuresChildrens(modalContent.value.id, 'OBJECT')
})
const onEsc = ev => {
  if (ev.key === 'Escape') {
    onClose()
  }
}
const onOpen = async () => {
  const ap = await getModalStatus()
  allowModalClose.value = ap && ap.done
  isModalOpen.value = true
  document.documentElement.style.overflow = 'hidden'
  window.addEventListener('keydown', onEsc)
}
const onClose = () => {
  if (allowModalClose.value) {
    isModalOpen.value = false
    document.documentElement.style.overflow = 'auto'
    window.removeEventListener('keydown', onEsc)
  }
}
const setModal = (type, content) => {
  modalContent.value = content
}
const openModal = () => {
  onOpen()
}
const closeModal = () => {
  onClose()
}

const setModalComplete = async () => {
  allowModalClose.value = true
  const ap = { parent: modalContent.value.parent, done: true }
  await createOrUpdateActivityProgress(modalContent.value.id, ap)
}

const getModalStatus = async () => {
  const ap = await getActivityProgress(modalContent.value.id)
  return ap
}

watch(
  () => modalContent.value,
  async () => {
    await fetchStructuresChildren(modalContent.value.id, {
      limit: 100,
      filter: '{"type": "OBJECT"}',
    })
    modalContentItems.value.forEach(ci => {
      if (ci.contentId) {
        gqlContentQuery(ci.contentId)
      }
    })
  },
)

export default () => {
  return {
    isModalOpen,
    modalContent,
    modalContentItems,
    allowModalClose,
    setModal,
    closeModal,
    openModal,
    setModalComplete,
    getModalStatus,
  }
}
