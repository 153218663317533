const queryContent = `
query queryContent($objectId: String!) {
  content(objectId: $objectId) {
    data {
      id
      namespace
      content
    }
    error {
      message
      status
    }
  }
}
`
const queryContentWithLocale = `
query queryContentWithLocale($objectId: String!, $locale: String!) {
  content(objectId: $objectId) {
    data {
      id
      namespace
      content
      localization(locale: $locale)
    }
    error {
      message
      status
    }
  }
}
`

export { queryContent, queryContentWithLocale }
