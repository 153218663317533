<template>
  <div class="c-navbar">
    <!-- will be replaced by font icon -->
    <TheButton to="/" class="c-navbar__btn" type="secondary-alt" circle>&lt;</TheButton>
    <TheHeading level="h3" class="c-navbar__title">{{ title }}</TheHeading>
    <ProgressSpinner :limit="limit" :current="current" />
    <ProgressBar :limit="limit" :current="current" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStructures } from '@/core'
import { getActivityProgress } from '@/services/progress'
import ProgressSpinner from '@/components/ProgressSpinner'
import ProgressBar from '@/components/ProgressBar'
import TheButton from '@/components/TheButton'
import TheHeading from '@/components/TheHeading'

export default {
  name: 'TopbarArena',

  components: {
    ProgressSpinner,
    ProgressBar,
    TheButton,
    TheHeading,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { getStructuresChildrens } = useStructures()

    const limit = computed(() => getStructuresChildrens(props.data.id, 'DIRECTORY').length)
    const current = ref(0)
    const title = computed(() => props.data.name)

    onMounted(async () => {
      const ap = await getActivityProgress(props.data.id)

      current.value = ap.itemsCompleted?.length || 0
    })

    return {
      limit,
      current,
      title,
    }
  },
}
</script>
