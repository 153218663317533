<template>
  <div class="c-activity-ed-item" :class="[`c-activity-ed-item--${status}`]">
    <button
      class="c-activity-ed-item__btn"
      :tabindex="canNavigate ? '0' : '-1'"
      :disabled="!canNavigate"
      @click="onItemClick"
    >
      <div class="c-activity-ed-item__number">
        {{ index + 1 }}
      </div>
      <div class="c-activity-ed-item__content">
        <div class="c-activity-ed-item__heading">
          {{ title }}
        </div>
        <div class="c-activity-ed-item__type">
          {{ type }}
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import useActivity from '@/composables/useActivity'

export default {
  name: 'ActivityExerciseDropdownItem',

  props: {
    index: Number,
    title: String,
    type: String,
    slug: String,
    id: String,
  },

  setup(props, { emit }) {
    const { activityProgressItems, getCurrentElement } = useActivity()

    const status = computed(() => {
      let s = 'incomplete'

      if (activityProgressItems.value.indexOf(props.id) !== -1) {
        s = 'completed'
      }
      if (getCurrentElement.value.slug === props.slug) {
        return 'current'
      }

      return s
    })

    const canNavigate = computed(() => status.value === 'completed' || status.value === 'current')
    const onItemClick = () => {
      if (!canNavigate.value) return

      emit('item-click')
    }

    return {
      status,
      canNavigate,
      onItemClick,
    }
  },
}
</script>