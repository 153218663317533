<template>
  <div class="c-topbar-progress">
    <div class="c-topbar-progress__bar" :class="[{'c-topbar-progress__bar--full': progress === 100}]" :style="{width: `${progress}%`}"></div>
  </div>
</template>

<script>
import useActivity from '@/composables/useActivity'

export default {
  setup() {
    const { progress } = useActivity()

    return {
      progress,
    }
  },
}
</script>