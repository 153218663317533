<template>
  <div class="c-progress-bar">
    <div class="c-progress-bar__label">
      {{ current }}/{{ limit }} {{$t('NAV_FINISHED_STEPS')}}
    </div>
    <div class="c-progress-bar__bar">
      <div class="c-progress-bar__progress"
        :style="`width: calc(${current} / ${limit} * 100%)`" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',

  props: {
    limit: Number,
    current: Number,
  },
}
</script>