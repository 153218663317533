<template>
  <div v-if="user" class="c-user" v-click-outside="dismissMenu">
    <TheButton
      type="button"
      class="c-user__btn"
      :class="{ 'c-user__btn--toggled': showMenu }"
      @click="showMenu = !showMenu"
    >
      <span class="c-user__btn-circle">
        {{ user.raw.name.givenName.slice(0, 1)
        }}{{ user.raw.name.familyName.slice(0, 1) }}
      </span>
      <span class="c-user__btn-text">{{ user.name.givenName }}</span>
    </TheButton>
    <div v-if="showMenu" class="c-user__menu">
      <div class="c-user__menu-button-wrapper">
        <TheButton
          basic
          class="c-user__menu-button c-user__menu-button--mobile"
          @click="onShowModal"
        >
          {{ $t('TOPBAR_MODAL_TEXT') }}
        </TheButton>
        <TheButton basic class="c-user__menu-button" @click="selectLang">
          {{ $t('TOPBAR_LANG_SELECT') }}
        </TheButton>
        <TheButton basic class="c-user__menu-button" @click="logout">
          {{ $t('TOPBAR_LOGOUT') }}
        </TheButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuth } from '@/core'
import { ref } from 'vue'
import TheButton from '@/components/TheButton'
import useCurtain from '@/composables/useCurtain'

export default {
  components: {
    TheButton,
  },

  setup(props, { emit }) {
    const { logout, user } = useAuth()
    const showMenu = ref(false)
    const { setCurtain } = useCurtain()

    const onShowModal = () => {
      emit('show-video-modal')
    }

    function dismissMenu() {
      showMenu.value = false
    }

    const selectLang = () => {
      setCurtain('CurtainLanguageSwitcher', 'langSwitcherUserInvoked', {
        allowDissmiss: false,
      })
    }

    return {
      showMenu,
      dismissMenu,
      logout,
      user,
      onShowModal,
      selectLang,
    }
  },
}
</script>
