<template>
  <header class="c-topbar">
    <div class="c-topbar__wrapper">
      <component :is="topbarComponent" :data="data" />
    </div>
    <TopbarProgress />
  </header>
</template>

<script>
import TopbarMain from '@/components/TopbarMain'
import TopbarArena from '@/components/TopbarArena'
import TopbarActivity from '@/components/TopbarActivity'
import TopbarLogin from '@/components/TopbarLogin'
import TopbarProgress from '@/components/TopbarProgress'

export default {
  name: 'Topbar',

  props: {
    topbarComponent: String,
    data: Object,
  },
  
  components: {
    TopbarMain,
    TopbarActivity,
    TopbarArena,
    TopbarLogin,
    TopbarProgress,
  },

  setup(props, { emit }) {
    const onShowModal = () => {
      emit('show-video-modal')
    }

    return {
      onShowModal,
    }
  },
}
</script>
