<template>
  <div class="c-topbar-activity">
    <div class="c-topbar-activity__left">
      <div v-if="activityIcon" class="c-topbar-activity__icon-wrapper">
        <img class="c-topbar-activity__icon" :src="activityIcon.url" :alt="activityTitle">
      </div>
      <TheHeading class="c-topbar-activity__title">
        {{ activityTitle }}
      </TheHeading>
    </div>
    <div class="c-topbar-activity__right">
      <div class="c-topbar-activity__exercise-dropdown">
        <TheButton type="primary" rounded icon="caret-down" iconPos="right" small class="c-topbar-activity__toggle-dropdown" @click="toggleDropdown">
          {{ currentElement + 1 }}
        </TheButton>

        <ActivityExerciseDropdown
          v-if="showDropdown"
          v-click-outside="vcoConfig"
          @close="closeDropdown"
        />
      </div>
      <div class="c-topbar-activity__close">
        <TheButton class="c-topbar-activity__close-btn" @click="onClose" type="secondary-alt" circle icon="close"></TheButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import useActivity from '@/composables/useActivity'
import useCurtain from '@/composables/useCurtain'

import ActivityExerciseDropdown from '@/components/ActivityExerciseDropdown'
import TheHeading from '@/components/TheHeading'
import TheButton from '@/components/TheButton'

export default {
  name: 'TopbarActivity',

  components: {
    ActivityExerciseDropdown,
    TheHeading,
    TheButton,
  },

  setup() {
    const { activityTitle, activityIcon, currentElement, goToArena } = useActivity()
    const { setCurtain } = useCurtain()
    const showDropdown = ref(false)
    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value
    }
    const closeDropdown = () => {
      showDropdown.value = false
    }
    const vcoConfig = {
      handler(ev) {
        if (ev.target.className === 'c-topbar-activity__toggle-dropdown') return
        closeDropdown()
      },
    }
    const onClose = () => {
      setCurtain('CurtainAction', 'ExitOverlay')
    }

    return {
      showDropdown,
      currentElement,
      activityTitle,
      activityIcon,
      vcoConfig,
      goToArena,
      closeDropdown,
      toggleDropdown,
      onClose,
    }
  },
}
</script>
