<template>
  <div class="c-progress-spinner">
    <div class="c-progress-spinner__loader-bg">
        <div class="c-progress-spinner__counter">{{ current }}/{{ limit }}</div>
    </div>
    
    <div v-for="i in limit" 
      :key="i"
      class="c-progress-spinner__holder-a"
      :class="[
        `c-progress-spinner__holder-a-${(i - 1) * Math.round(100/limit)}-${i * Math.round(100/limit)}`,
        { 'c-progress-spinner__holder-a--hide': i > current }
      ]">
        <div class="c-progress-spinner__holder-b"
          :class="`c-progress-spinner__holder-b-${limit}`">
            <div class="c-progress-spinner__loader-spinner"></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressCircle',

  props: {
    limit: Number,
    current: Number,
  },
}
</script>