import { ref } from 'vue'

const defaultOptions = { allowDissmiss: true }

const isCurtainOpen = ref(false)
const curtainComponent = ref('')
const curtainType = ref('')
const curtainData = ref(null)
const curtainOptions = ref(defaultOptions)

const setCurtain = (componentName, type, options = null, data = null) => {
  curtainComponent.value = componentName
  curtainType.value = type
  curtainData.value = data
  curtainOptions.value = { ...defaultOptions, ...options }

  showCurtain()
}
const showCurtain = () => {
  isCurtainOpen.value = true
}
const closeCurtain = () => {
  isCurtainOpen.value = false
  curtainComponent.value = ''
  curtainType.value = ''
  curtainData.value = null
  curtainOptions.value = defaultOptions
}

export default () => ({
  isCurtainOpen,
  curtainComponent,
  curtainType,
  curtainData,
  curtainOptions,
  setCurtain,
  showCurtain,
  closeCurtain,
})
