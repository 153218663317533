import { computed } from 'vue'
import { useTenant, useLocalizations, useContents } from '@/core'
import i18n from '@/i18n'
import { queryContentWithLocale } from '@/graphql'
import { postGql } from '@/core/services/graphql'

const { tenant } = useTenant()

const { setObject } = useContents()
const { setLocalization } = useLocalizations()
const isFallbackLanguage = computed(
  () => tenant.value.langs[0].code === i18n.global.locale,
)

const gqlContentQuery = async (contentId, locale = '') => {
  const lang = locale || i18n.global.locale
  const gqlRes = await postGql(tenant.value.namespace, {
    query: queryContentWithLocale,
    variables: {
      objectId: contentId,

      locale: lang,
    },
  })
  if (!gqlRes.data.content.data) return
  setObject([gqlRes.data.content.data])

  if (gqlRes.data.content.data.localization) {
    gqlRes.data.content.data.localization.content = {
      content: gqlRes.data.content.data.localization.content,
    }
    gqlRes.data.content.data.localization.service = 'edtech.content'
    gqlRes.data.content.data.localization.locale = lang
    gqlRes.data.content.data.localization.objectId = contentId
    setLocalization(gqlRes.data.content.data.localization)
  }
  return gqlRes
}

export default () => ({
  isFallbackLanguage,
  gqlContentQuery,
})
