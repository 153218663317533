<template>
  <TheButton class="c-logo" to="/">
    <img class="c-logo--brand"
      :alt="tenant.name"
      src="/themes/synopsis/assets/Logo.png"
    />
    <img class="c-logo--app"
      :alt="tenant.name"
      src="/themes/synopsis/assets/svg/Grammatic.svg"
    />
  </TheButton>
</template>

<script>
import { useTenant } from '@/core'
import TheButton from '@/components/TheButton'

export default {
  name: 'Logo',

  components: {
    TheButton,
  },

  setup() {
    const { tenant } = useTenant()

    return {
      tenant,
    }
  },
}
</script>

