<template>
  <Logo />
  <div class="c-topbar__modal">
    <TheButton  @click="openModal">
      {{ $t('TOPBAR_MODAL_TEXT') }}
    </TheButton>
  </div>
  <UserMenu @show-video-modal="openModal" />
</template>

<script>
import { ref } from 'vue'
import useModal from '@/composables/useModal'
import UserMenu from '@/components/UserMenu'
import TheButton from '@/components/TheButton'
import Logo from '@/components/Logo'

export default {
  name: 'TopbarMain',
  
  components: {
    UserMenu,
    TheButton,
    Logo,
  },

  setup() {
    const title = ref('title')
    const { openModal } = useModal()

    return {
      title,
      openModal,
    }
  },
}
</script>