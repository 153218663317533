<template>
  <div class="c-activity-exercise-dropdown">
    <div ref="container" class="c-activity-exercise-dropdown__wrapper">
      <ActivityEDItem
        v-for="(item, i) in activityChildrens"
        :key="item.id" 
        :index="i"
        :title="item.name"
        :type="item.contentType"
        :slug="item.slug"
        :id="item.id"
        @item-click="onItemClick(item.slug)"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import PerfectScrollbar from 'perfect-scrollbar'

import useActivity from '@/composables/useActivity'
import ActivityEDItem from '@/components/ActivityEDItem'

export default {
  name: 'ActivityExerciseDropdown',

  components: {
    ActivityEDItem,
  },

  setup(props, { emit }) {
    const container = ref(null)
    const PS = ref(null)
    const { activityChildrens, setElement } = useActivity()
    const onItemClick = slug => {
      setElement(slug)
      emit('close')
    }
    onMounted(() => {
      PS.value = new PerfectScrollbar(container.value)
    })

    return {
      container,
      activityChildrens,
      onItemClick,
    }
  },
}
</script>