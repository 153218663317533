<template>
  <component :is="level" class="c-heading">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'TheHeading',

  props: {
    level: {
      type: String,
      default: 'h2',
    },
  },
}
</script>